import React from 'react'
import './SiteMap.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import { useState } from 'react'
import { useEffect } from 'react'
import getCollection from '../../utils/db/getCollection'
import { isEmpty, orderBy } from 'lodash'
import Spinner from '../../ui/Spinner/Spinner'

function SiteMap() {
  const [tours, setTours] = useState(null)

  useEffect(() => {
    getCollection({
      path: 'tours',
      docIdName: 'tourId',
      whereQueries: [{ fieldPath: 'isPublished', op: '==', value: true }],
    }).then((data) => {
      setTours(orderBy(data, ['pos'], ['asc']))
    })
  }, [])

  return !isEmpty(tours) ? (
    <div className="SiteMap DefaultSection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title={'Карта сайта'} />
            <div className="SiteMap-Container">
              <ul>
                <li>
                  <a href="https://iterrainc.ru">Главная</a>
                </li>
                <li>
                  <a href="https://iterrainc.ru/#tours-catalog">
                    Каталог туров
                  </a>
                </li>
                <li>
                  <ul>
                    {tours.map((t) => (
                      <li>
                        <a href={`https://iterrainc.ru/tours/${t.tourId}#tour`}>
                          {t.ru.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <a href="https://iterrainc.ru/#tour-constructor">
                    Конструктор тура
                  </a>
                </li>
                <li>
                  <a href="https://iterrainc.ru/#feeds">Отзывы</a>
                </li>
                <li>
                  <a href="https://iterrainc.ru/#partners">Наши партнёры</a>
                </li>
                <li>
                  <a href="https://iterrainc.ru/#contacts">Контакты</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        {tours.map((t) => (
          <url>
            <loc>{`https://iterrainc.ru/tours/${t.tourId}`}</loc>
            <lastmod>2023-11-12T17:46:42+03:00</lastmod>
            <priority>1.0</priority>
          </url>
        ))}
      </div> */}
    </div>
  ) : (
    <Spinner type="module" />
  )
}

export default SiteMap
