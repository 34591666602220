import React, { useContext } from 'react'
import './LegacyInfo.sass'
import { AuthContext } from '../../App'

function LegacyInfo() {
  const { lang } = useContext(AuthContext)

  return (
    <div className="LegacyInfo">
      <div className="container">
        <div className="row">
          {lang === 'ru' ? (
            <div className="col-12 LegacyInfo-Container">
              <p>
                Юридический адрес: Общество с ограниченной ответственностью
                Ай-Терра, ИНН 5190087359, 183035, Мурманская область, г.
                Мурманск, ул. Александра Невского, д. 2, кабинет 3
              </p>
              <p>
                Фактический адрес: Мурманская область, г. Мурманск, пр-кт Ленина
                82а, каб 216а
              </p>
              <p>
                Федеральное агентство по туризму:{' '}
                <a
                  href="https://tourism.gov.ru/operators/show.php?id=115161"
                  target="blank"
                >
                  реестровый номер РТО 023114
                </a>
              </p>
              <a href="/docs/offer.pdf" target="blank">
                Оферта "ООО Ай-Терра"
              </a>
              ,{' '}
              {/* <a href="/docs/pd2.pdf" target="blank">
              Инструкция по регистрации в качестве опрератора обработки пд
            </a>
            ,{' '} */}
              {/* <a href="/docs/photo.pdf" target="blank">
              Согласие на размещение фото
            </a>
            ,{' '} */}
              <a href="/docs/policy.pdf" target="blank">
                Политика конфиденциальности
              </a>
              ,{' '}
              <a href="/docs/pers.pdf" target="blank">
                Согласие на обработку персональных данных
              </a>
              ,{' '}
              <a href="https://iterrainc.ru/sitemap" target="blank">
                Карта сайта
              </a>
              <p style={{ marginTop: '12px' }}>
                Персональные данные на сайте размещены с согласия, условия
                запрета не установлены
              </p>
            </div>
          ) : (
            <div className="col-12 LegacyInfo-Container">
              <p>
                Legal address: Limited Liability Company Ai-Terra, INN
                5190087359, 183035, Murmansk region, Murmansk, Alexander Nevsky
                str., 2, office 3
              </p>
              <p>
                Actual address: Murmansk region, Murmansk, 82a Lenin Ave .,
                office 216a
              </p>
              <p>
                Federal Agency for Tourism:{' '}
                <a
                  href="https://tourism.gov.ru/operators/show.php?id=115161"
                  target="blank"
                >
                  RTO registration number 023114
                </a>
              </p>
              <a href="/docs/offer.pdf" target="blank">
                The offer of "Ai-Terra LLC"
              </a>
              ,{' '}
              {/* <a href="/docs/pd2.pdf" target="blank">
              Инструкция по регистрации в качестве опрератора обработки пд
            </a>
            ,{' '} */}
              {/* <a href="/docs/photo.pdf" target="blank">
              Согласие на размещение фото
            </a>
            ,{' '} */}
              <a href="/docs/policy.pdf" target="blank">
                Privacy policy
              </a>
              ,{' '}
              <a href="/docs/pers.pdf" target="blank">
                Consent to the processing of personal data
              </a>
              ,{' '}
              <a href="https://iterrainc.ru/sitemap" target="blank">
                Site map
              </a>
              <p style={{ marginTop: '12px' }}>
                Personal data on the site is posted with consent, the conditions
                are not prohibited
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LegacyInfo
