import React, { useContext, useEffect, useRef } from 'react'
import './Nav.sass'
import Hamburger from 'react-hamburger-menu'
import Media from 'react-media'
import { withRouter } from 'react-router-dom'
import Logo from '../../ui/Logo/Logo'
import SiteNavLink from './SiteNavLink'
import { usePopUp } from '../../hooks/usePopUp'
import useScrollPosition from '../../hooks/useScrollPosition'
import { AuthContext } from '../../App'
import PhoneChooser from './PhoneChooser/PhoneChooser'
import location from '../../locale/location'
import LangChooser from './LangChooser/LangChooser'

function Nav({ user, ...router }) {
  const hamRef = useRef()
  const navRef = useRef()
  const [menuIsOpen, setMenuIsOpen] = usePopUp(hamRef, navRef)
  const pos = useScrollPosition()
  const { lang } = useContext(AuthContext)

  useEffect(() => {
    setMenuIsOpen(false)
  }, [router.location.pathname, router.location.hash])

  return (
    <nav
      id="nav"
      className={[
        'Site-Nav',
        ...(pos > 0 ? ['Site-Nav_theme_shadow'] : []),
      ].join(' ')}
    >
      <Media
        query="(max-width: 1024px)"
        render={() => (
          <span ref={hamRef}>
            <Hamburger
              isOpen={menuIsOpen}
              width={24}
              height={16}
              strokeWidth={2}
              className="Site-Hamburger-Container"
              color="#303030"
              borderRadius={2}
              animationDuration={0.2}
            />
          </span>
        )}
      />
      <Logo theme="accent" navLink path="/#home" />
      <div
        ref={navRef}
        className="Site-Nav-Menu"
        style={menuIsOpen ? { display: 'grid' } : {}}
      >
        <SiteNavLink
          title={location[lang].links.tours}
          path="/#tours-catalog"
        />
        <SiteNavLink
          title={location[lang].links.constructor}
          path="#tour-constructor"
        />
        <SiteNavLink title={location[lang].links.feeds} path="#feeds" />
        <SiteNavLink title={location[lang].links.partners} path="#partners" />
        <SiteNavLink title={location[lang].links.contacts} path="#contacts" />
        <div className="Nav-Divider" />
        <PhoneChooser />
        <LangChooser />
      </div>
      <div className="MobileContacts">
        <PhoneChooser />
        <LangChooser />
      </div>
    </nav>
  )
}

// const mapStateToProps = (state) => ({ user: state.auth.user })

export default withRouter(Nav)
