import React, { useEffect, createContext, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from './pages/Home/Home'
import SitePage from './pages/SitePage/SitePage'
import { firebase } from './config/firebase'
import ScrollToTop from './ui/ScrollToTop/ScrollToTop'
import CookieNotification from './components/CookieNotification/CookieNotification'
import TourPage from './components/TourPage/TourPage'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import getDoc from './utils/db/getDoc'
import { isEmpty } from 'lodash'
import NotFound from './ui/NotFound/NotFound'
import SiteMap from './components/SiteMap/SiteMap'

export const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [showContactUs, setShowContactUs] = useState({
    show: false,
    title: '',
    emailSubject: '',
  })
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'ru')
  const [settings, setSettings] = useState(null)

  useEffect(() => {
    getDoc({ path: 'settings', docId: '0tOG33b4DzEfG8BS9x7x' }).then((data) =>
      setSettings(data)
    )
  }, [])

  useEffect(() => {
    const userStateListener = firebase.auth().onAuthStateChanged(setUser)
    return () => {
      userStateListener()
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{ user, settings, showContactUs, setShowContactUs, lang, setLang }}
    >
      {!isEmpty(settings) && children}
    </AuthContext.Provider>
  )
}

function App() {
  return (
    <AuthProvider>
      <ScrollToTop />
      <SitePage>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/tours/:id" exact component={TourPage} />
          {/* <Route path="/project/:id" exact component={ProjectPage} />
        <Route
          path={['/manage-project', '/manage-project/:id']}
          exact
          component={ManageProjectPage}
        />
        <Route path="/admin" exact component={Auth} /> */}
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/sitemap" exact component={SiteMap} />
          <Route path="" component={NotFound} />
        </Switch>
      </SitePage>
      <CookieNotification />
    </AuthProvider>
  )
}

export default App
