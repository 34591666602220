import React, { useContext } from 'react'
import Icon from '../../../ui/Icon/Icon'
import { AuthContext } from '../../../App'
import './PhoneChooser.sass'
import { useRef } from 'react'
import { usePopUp } from '../../../hooks/usePopUp'
import { useState } from 'react'

function PhoneChooser() {
  const phoneRef = useRef()
  const ddRef = useRef()
  const [showPopUp, setShowPopUp] = usePopUp(phoneRef, ddRef)
  const { settings } = useContext(AuthContext)
  const phones = settings.phone.split(',')

  return (
    <div className="PhoneChooser">
      <Icon name="mobile" weight="regular" className="ChooserIcon" />
      <span ref={phoneRef} className="PhoneChooser-ActivePhone">
        {phones[0]}
      </span>
      <Icon name={showPopUp ? 'angle-up' : 'angle-down'} weight="regular" />
      {showPopUp && (
        <div ref={ddRef} className="PhoneChooser-Container">
          {phones.map((p) => (
            <a href={`tel:${p}`} className="PhoneChooser-Phone">
              {p}
            </a>
          ))}
        </div>
      )}
    </div>
  )
}

export default PhoneChooser
