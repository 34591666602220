import React from 'react'
import './NotFound.sass'
import { NavHashLink } from 'react-router-hash-link'
import scrollWithOffset from '../ScrollToTop/scrollWithOffset'
import Button from '../Button/Button'
import notFoundImg from './404.jpg'

function NotFound() {
  return (
    <div
      className="NotFound DefaultSection"
      style={{ backgroundImage: `url(${notFoundImg})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="NotFound-Container">
              <p className="Title">Ошибка 404</p>
              <p className="Description">
                К сожалению, у нас нет такой страницы. Но у нас есть
                замечательная подборка готовы туров. Рекомендуем взглянуть!
              </p>
              <div className="Buttons">
                <NavHashLink
                  to="/#tours-catalog"
                  smooth
                  scroll={(el) => scrollWithOffset(el, -64)}
                >
                  <Button
                    title={'Перейти к каталогу туров'}
                    fill="accent"
                    theme="solid"
                  />
                </NavHashLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
