import React, { useContext, useRef } from 'react'
import Icon from '../../../ui/Icon/Icon'
import { AuthContext } from '../../../App'
import './LangChooser.sass'
import { usePopUp } from '../../../hooks/usePopUp'

function LangChooser() {
  const { lang, setLang } = useContext(AuthContext)
  const langRef = useRef()
  const ddRef = useRef()
  const [showPopUp, setShowPopUp] = usePopUp(langRef, ddRef)

  const onChange = (id) => {
    setLang(id)
    localStorage.setItem('lang', id)
    setShowPopUp(false)
  }

  return (
    <div className="LangChooser">
      <Icon name="globe-americas" weight="regular" className="ChooserIcon" />
      <span ref={langRef} className="LangChooser-ActiveLang">
        {lang}
      </span>
      <Icon name={showPopUp ? 'angle-up' : 'angle-down'} weight="regular" />
      {showPopUp && (
        <div ref={ddRef} className="LangChooser-Container">
          <span className="LangChooser-Lang" onClick={() => onChange('ru')}>
            RU
          </span>
          <span className="LangChooser-Lang" onClick={() => onChange('en')}>
            EN
          </span>
        </div>
      )}
    </div>
  )
}

export default LangChooser
