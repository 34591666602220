import React, { useContext } from 'react'
import './Home.sass'
import Hero from '../../components/Hero/Hero'
import Feeds from '../../components/Feeds/Feeds'
import Faq from '../../components/Faq/Faq'
import TourCatalog from '../../components/TourCatalog/TourCatalog'
import TourConstructor from '../../components/TourConstructor/TourConstructor'
import Partners from '../../components/Partners/Partners'
import ContactUs from '../../components/ContactUs/ContactUs'
import LegacyInfo from '../../components/LegacyInfo/LegacyInfo'
import { AuthContext } from '../../App'
import Seo from '../../ui/Seo/Seo'

function Home() {
  const { settings } = useContext(AuthContext)
  return (
    <div className="Home">
      <Seo
        title={settings.meta_title}
        description={settings.meta_desc}
        keywords={settings.meta_keywords}
      />
      <Hero />
      <TourCatalog />
      <TourConstructor />
      <Feeds />
      <Faq />
      <Partners />
      <ContactUs />
      <LegacyInfo />
    </div>
  )
}

export default Home
