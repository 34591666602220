import React, { useContext, useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import Icon from '../../ui/Icon/Icon'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import './CookieNotification.sass'
import { AuthContext } from '../../App'

function CookieNotification() {
  const { lang } = useContext(AuthContext)

  const [acceptedCookie, setAcceptedCookie] = useState(
    localStorage.getItem('acceptedCookies')
  )

  const onCookiesAccept = () => {
    localStorage.setItem('acceptedCookies', true)
    setAcceptedCookie(true)
  }

  return !acceptedCookie ? (
    <div className="Site-CookieNotification">
      <div className="CookieNotification-Container">
        <div className="CookieNotification-Text">
          {lang === 'ru'
            ? `Используя веб-сайт iterrainc.ru, вы соглашаетесь с использованием
          файлов cookies, а также`
            : `Using the website iterrainc.ru you agree to the use of cookies, as well as`}
          <a href="/docs/policy.pdf" target="blank">
            {lang === 'ru'
              ? 'политикой обработки персональных данных'
              : 'personal data processing policy'}
          </a>{' '}
        </div>
        <div className="CookieNotification-Button">
          <Icon name="times" onClick={onCookiesAccept} />
        </div>
      </div>
    </div>
  ) : null
}

export default CookieNotification
