import React from 'react'
// import Footer from '../../components/Footer/Footer'
import Nav from '../../components/Nav/Nav'
import './SitePage.sass'
import { Helmet } from 'react-helmet'

function SitePage({ children }) {
  return (
    <>
      <Helmet>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Nav />
      {children}
      <div
        className="HiddenContacts"
        itemscope
        itemtype="http://schema.org/Organization"
      >
        <span itemprop="name">Яндекс</span>
        Контакты:
        <div
          itemprop="address"
          itemscope
          itemtype="http://schema.org/PostalAddress"
        >
          Адрес:
          <span itemprop="streetAddress">
            Мурманская область, г. Мурманск, пр-кт Ленина 82а, каб 216а
          </span>
          <span itemprop="postalCode">183035</span>
          <span itemprop="addressLocality">Мурманск</span>,
        </div>
        Телефон:
        <span itemprop="telephone">+7 921 159-29-29</span>,
        <span itemprop="telephone">+7 921 034-55-15</span>, Электронная почта:{' '}
        <span itemprop="email">iterra.work@gmail.com</span>
      </div>
    </>
  )
}

export default SitePage
